import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { List, Drawer, IconButton } from "@mui/material";

import { Logo } from "../../../logo/Logo";
import { NAV } from "../../../../config-global";
import { Scrollbar } from "../../../scrollbar/Scrollbar";

import { NavProps } from "../types";
import NavList from "./NavList";
import { Iconify } from "../../../iconify/Iconify";

// ----------------------------------------------------------------------
export default function NavMobile({ isOffset, data }: NavProps) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
  }, [pathname]);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <IconButton
        onClick={handleOpen}
        sx={{
          ml: 1,
          color: "text.primary",
        }}
      >
        <Iconify icon="eva:menu-2-fill" width={30} />
      </IconButton> */}

      {/* <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_BASE,
            backgroundColor: theme.palette.background.default,
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          <div className="w-full flex item-center flex-col px-8">
            <button className="btn-primary !p-[8px] !bg-[#8fd7a8] max-w-[200px] mx-auto">
              <a
                className="text-[18px]"
                href="https://freelancer.com"
                target="_blank"
                rel="noreferrer"
              >
                WhitePaper
              </a>
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0012 1L15 6L10.0012 11M1 6H14.86"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <List component="nav" disablePadding>
            {data.map((link) => (
              <NavList key={link.title} item={link} />
            ))}
          </List>
        </Scrollbar>
      </Drawer> */}
    </>
  );
}
