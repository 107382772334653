import ReactDOM from "react-dom/client";

import "./index.css";
import "./assets/style/global.scss";
import "simplebar-react/dist/simplebar.min.css";

import { Routers } from "./routers";
import { ThemeProvider } from "./theme";
import reportWebVitals from "./reportWebVitals";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, bsc } from "wagmi/chains";

const chains = [arbitrum, mainnet, bsc];
const projectId = "199736738b8899d74dafdb163688e11d";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <WagmiConfig config={wagmiConfig}>
      <ThemeProvider>
        <Routers />
      </ThemeProvider>{" "}
    </WagmiConfig>
    <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
