import "./home.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { ComponentHero } from "../com-hero/comHero";
export const HomePage = () => {
  return (
    <div className="flex flex-col bg-[#1b8ad2]" id="buy-ai-now">
      <ComponentHero />
      <CustomDivider />
    </div>
  );
};

const CustomDivider = () => {
  return <div className="" />;
};
